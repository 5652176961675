"use client";

import React from "react";
import classNames from "classnames";
import { AccordionTabContent } from "./AccordionTabContent";
import { CardBox } from "../CardBox";
import { TabsProps } from "./types";

import "./styles.scss";

export const Tabs = ({ tabsData, light, className }: TabsProps) => {
  const classnames = classNames(`tabs`, {
    ["tabs--light"]: light,
    [`${className}`]: className,
  });

  return (
    <div className={classnames}>
      {tabsData.map((tab, i) => (
        <React.Fragment key={i}>
          <input
            type="radio"
            name="tabset"
            id={`tab-${tab.id}`}
            aria-controls={tab.label}
            defaultChecked={i == 0}
            className="tabs-input"
          />
          <label
            htmlFor={`tab-${tab.id}`}
            className="tabs-label">
            {tab.label}
          </label>
        </React.Fragment>
      ))}

      <div className="tab-panels">
        {tabsData?.map((tab, i) => (
          <section
            id={`tab-panel-${i}`}
            className="tab-panel"
            key={i}>
            <>
              {tab.type === "accordion" ? (
                <>
                  {Array.isArray(tab.content) &&
                    tab.content.map((item, i) => (
                      <AccordionTabContent
                        key={i}
                        item={item}
                        index={i}
                        customId={tab.id}
                      />
                    ))}
                </>
              ) : (
                <>
                  {!Array.isArray(tab.content) ? (
                    <p>{tab.content}</p>
                  ) : (
                    <div>
                      {Array.isArray(tab.content) &&
                        tab.content.map((item, i) => (
                          <CardBox
                            item={item}
                            key={i}
                          />
                        ))}
                    </div>
                  )}
                </>
              )}
            </>
          </section>
        ))}
      </div>
    </div>
  );
};
