import React from "react";
import { Banner } from "@/components/Banner";
import { BannerWrapperSize } from "../Banner/types";
import { Typography } from "../Typography";
import { Preview } from "../Preview";
import { previewHome } from "@/data/types/home";

export const GridModule = ({
  data,
  wrapperSize,
}: {
  data: previewHome;
  wrapperSize: BannerWrapperSize;
}) => {
  const { items, subtitle, title, data_qa, backgroundColor } = data;

  return (
    <Banner
      small
      light
      customBgColor={backgroundColor}
      wrapperSize={wrapperSize}
      customClass="banner-preview">
      <Typography
        Tag={"h2"}
        spacing={4}>
        {title}
      </Typography>
      <Typography
        Tag={"p"}
        size="l"
        spacing={4}>
        {subtitle}
      </Typography>
      <Preview
        items={items}
        dataQA={data_qa}
      />
    </Banner>
  );
};
